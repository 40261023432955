<template>
	<div>
	    <a-config-provider :locale="zh_CN">
			<a-card>
				<a-row>
					<a-col :span="6">
						<a-row style="margin-bottom: 20px;">
							<a-col :span="24">
								<!-- <a-button type="primary" @click="createOrEdit('create')"> -->
								<a-col :span="3">
									<a-icon type="plus" @click="createOrEdit('create')" v-if="isGranted('audit_items_create')"/>
								</a-col>
								    <!-- 新增 -->
								<!-- </a-button> -->
								<!-- <a-button type="primary" @click="createOrEditMachineSupplier('edit')"> -->
								<a-col :span="3">
									<a-icon type="edit" @click="createOrEditMachineSupplier('edit')" v-if="isGranted('audit_items_edit')"/>
								</a-col>
									<!-- 修改 -->
								<!-- </a-button> -->
								<!-- <a-button type="danger" @click="batchdelete"> -->
								<a-col :span="3">
								    <a-icon type="delete" @click="batchdelete" v-if="isGranted('audit_items_delete')"/>
								</a-col>
								    <!-- 删除 -->
								<!-- </a-button> -->
							</a-col>
							<a-col :span="24">
								<div style="width:100%;overflow:auto;" :style="styObj">
									<div class="con" style="width: 100%;min-width: 180px;">
										<a-spin :spinning="isTreeLoading">
											<a-tree
													style="width:100%"
													:expanded-keys="expandedTreeKeys"
													:selected-keys="selectedTreeKeys"
													v-model="checkedTreeKeys"
													:tree-data="treeData"
													:replace-fields="treeReplaceFields"
													@select="onTreeSelect"
													@expand="onTreeExpand"
											/>
										</a-spin>
								</div>
								</div>
							</a-col>
						</a-row>
					</a-col>
					<!-- <a-col :span="1">
						
					</a-col> -->
					<a-col :span="18" style="padding-left: 10px;">
						<a-row style="margin-bottom: 20px;">
						    <a-col :span="12">
						        <a-button type="primary" @click="create('create')" v-if="isGranted('audit_items_create')">
						            <a-icon type="plus"/>
						            {{l('Create')}}
						        </a-button>
						        <a-button type="danger" @click="delete1" v-if="isGranted('audit_items_delete')">
						            <a-icon type="delete"/>
						            {{l('Delete')}}
						        </a-button>
								<a-button type="primary" @click="Export" v-if="isGranted('audit_items_import')">
								    <a-icon type="import"/>
								    {{l('import')}}
								</a-button>
								<a-button type="primary" @click="handleMenuClick" v-if="isGranted('audit_items_export')">
								    <a-icon type="import"/>
								    {{l('export')}}
								</a-button>
						    </a-col>
							
						    <a-col :span="12" class="d-flex justify-content-end">
								<a-tree-select
								        style="width: 200px"
								        :tree-data="workProcessData"
								        :replace-fields="treeReplace"
								        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								        :placeholder="l('please_choose_the_type')"
								        allow-clear
								        tree-default-expand-all
										@change="workProcessClassChange"
								>
								</a-tree-select>
									<!-- <a-select showSearch :filterOption="filterOption" style="width: 200px;text-align: left;" dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"  v-model="workProcessClass"  placeholder="请输入类型搜索..." allow-clear @change="filteronchange">
										<a-select-option v-for="item in workProcessData" :key="item.id" @click="workProcessClassChange" :title="item.name">
										  {{ item.name }}
										</a-select-option>
									</a-select> -->
									<a-input-search :placeholder="l('please_enter_the_audit_item_to_search')" v-model.trim="filterText" enterButton
													style="width: 200px;margin-left: 10px;" @search="filteronchange"
							
									></a-input-search>
						    </a-col>
						</a-row>
						<a-spin :spinning="isLoading">
						    <a-table :columns="columns"
						             :data-source="tableData"
						             :customRow="customRow"
									 :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
									 :rowKey="(record)=>record.id"
						             :scroll="{x:1000,y:610}"
									 :pagination="false"
									 size="small"
									 @change="handleTableChange"
						    >
							<span slot="enable" slot-scope="text">
								<span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
								<span v-else><a-icon type='close'/></span>
							 </span>
							 <span slot="auditNeedInput" slot-scope="text">
							 	<span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
							 	<span v-else><a-icon type='close'/></span>
							  </span>
								 <span slot="name" slot-scope="text,record">
									 <div style="-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;">
										 <a @click.stop="view(record)">{{text}}</a>
									 </div>
								 </span>
								 <span slot="action" slot-scope="text,record">
									 <a @click.stop="createOrEditM(record)" v-if="isGranted('audit_items_edit')">
										 <a-icon type="edit"/>{{l('Edit')}}
									 </a>
									 <font v-if="isGranted('audit_items_delete')&&isGranted('audit_items_edit')">  |  </font>
									 <a @click.stop="delete2(record)" v-if="isGranted('audit_items_delete')">
										 <a-icon type="delete"/>{{l('Delete')}}
									 </a>
									 <font v-if="isGranted('audit_items_delete')&&isGranted('audit_items_enable')">  |  </font>
									 <a-popconfirm v-if="record.enable==true&&isGranted('audit_items_enable')" :title="l('whether_to_disable_this_audit_item')" @confirm="() => handleEdit(record)" :ok-text="l('Ok')" :cancel-text="l('Cancel')">
										<a  v-if="record.enable" style="color: red;">
											<a-icon type="close-circle" />{{l('disable')}}
										</a>
									 </a-popconfirm>
									 <a-popconfirm v-if="record.enable==false&&isGranted('audit_items_enable')" :title="l('this_review_item')" @confirm="() => handleEdit(record)" :ok-text="l('Ok')" :cancel-text="l('Cancel')">
										<a  v-if="record.enable==false">
											<a-icon type="check-circle" />{{l('enable')}}
										</a>
									 </a-popconfirm>
								 </span>
						    </a-table>
						</a-spin>
						<a-pagination
						        class="pagination"
						        :total="totalItems"
						        showSizeChanger
						        showQuickJumper
								:show-total="total => `共有 ${totalItems} 条`"
								:defaultPageSize="request.maxResultCount"
								:pageSizeOptions="pageSizeOptions"
						        @change="onChangePage"
						        @showSizeChange="showSizeChange"/>
						<!-- <a-pagination
						        class="pagination"
						        :total="totalItems"
						        showSizeChanger
						        showQuickJumper
						        :showTotal="showTotalFun"
						        @change="onChangePage"
						        @showSizeChange="showSizeChange"/> -->
					</a-col>
				</a-row>
				
			</a-card>
		</a-config-provider>
	</div>
</template>

<script>
	import { AppComponentBase } from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import { AuditItemGroupServiceProxy,AudititemServiceProxy,AuditItemtypeServiceProxy } from '../../../shared/service-proxies';
	import { ModalHelper } from '@/shared/helpers';
	import CreatErrorCode from "./new-item/new-item.vue";
	import view from "./view/view.vue";
	import Creat from "./new-audit/new-audit.vue";
	import ImportExcelModel from './import-excel-model/import-excel-model';
	import {fileDownloadService} from "../../../shared/utils";
	
	let _this;
	export default {
	    name: "audit-items",
	    mixins: [AppComponentBase],
	    components: {
			CreatErrorCode,
			view,
			Creat,
			ImportExcelModel
	    },
		created() {
		    _this = this;
		    this.AuditItemGroupServiceProxy = new AuditItemGroupServiceProxy(this.$apiUrl, this.$api);
			this.AudititemServiceProxy = new AudititemServiceProxy(this.$apiUrl, this.$api);
			this.AuditItemtypeServiceProxy = new AuditItemtypeServiceProxy(this.$apiUrl, this.$api);
			this._fileDownloadService = fileDownloadService;
		},
		mounted() {
		    this.getTree();
			this.getData();
			this.getWorkProcessData();

			window.addEventListener('resize', this.changeHeight);
			this.changeHeight();
		},
		data() {
		    return {
		        zh_CN,
		        isEdit: false,
		        isLook: false,
			
		        filterText: undefined,
				totalItems: 0,//总数
				// 当前页码
				pageNumber: 1,
				// 共多少页
				totalPages: 1,
				// 条数显示范围
				pagerange: [1, 1],
				// 显示条数
				pageSizeOptions: ["10", "20", "50", "100", "500"],
				request: {maxResultCount: 20, skipCount: 0},
			
		        tableData: [],
		        columns: [
		            {title: this.l('audititem.audititemtypeid'),width: 150,dataIndex: 'auditItemTypeListName',align: 'center',},
                    {title: this.l('Code'), width: 100, dataIndex: 'code', align: 'left',ellipsis: true},
		            {title: this.l('audititem.name'), width: 500, dataIndex: 'name', align: 'left',scopedSlots: {customRender: "name"}},
					{title: this.l('audititem.audineedinput'),width: 100,dataIndex: 'auditNeedInput',align: 'center',scopedSlots: {customRender: "auditNeedInput"}},
					{title: this.l('enable'),width: 50,dataIndex: 'enable',align: 'center',scopedSlots: {customRender: "enable"}},
					{title: this.l('sn'),width: 70,dataIndex: 'sn',align: 'center',sorter:true},
					{title: this.l('Actions'),width: 200,dataIndex: 'action',align: 'center',scopedSlots: {customRender: "action"},fixed: 'right',},
		        ],
		        selectedRowKey: [], // 选择的数据Id
				selectedRowList: [],
		        selectedRow: [],//选择的整行数据
		        isLoading: false,//加载中
				children: [],
				treeData: [],
				treeReplaceFields: {
				    title: 'name',
				    key: 'id',
				    code: 'code'
				},
				treeReplace: {
				    title: 'name',
				    key: 'id',
				    value: 'id'
				},
				styObj:{
					height:0
				},

				checkedTreeKeys: [],
				isTreeEdit: false,
				isTreeCreate: false,
				isTreeLoading: false,//加载中
				treeNodeCode: undefined,
				expandedTreeKeys: [],
				selectedTreeKeys: [],
				selected: [],
				workProcessClass: undefined,
				workProcessData: [],
				teby: [],
				enable: [],
				_fileDownloadService: undefined,
		    }
		},
		methods: {
			//div高度
			changeHeight(){
					this.styObj.height=window.innerHeight-199+'px';
			},
			//获取数据
			getTree() {
				this.treeData = [];
				this.isTreeLoading = true;
				this.AuditItemGroupServiceProxy.getPaged(
				).finally(() => {
				    this.isTreeLoading = false;
				}).then(rel => {
					console.log(rel);
					if(rel.length <= 0){
					    this.treeData = [{name: '审核项', code: '', id: "null", children: rel,}];
					}
					else{
						this.treeData = [];
					    rel.forEach(item=>{
					        this.treeData.push(item)
					    });
					
					    console.log(rel)
					}
					
					this.expandedTreeKeys.push('null');
					this.recursion(rel);
				})
			},
			//排序
			handleTableChange(pagination, filters, sorter) {
			    console.log(sorter);
			    if (sorter.order != undefined){
			        this.Sorting = sorter.field + " " + sorter.order.replace("end","");
			    }
			    else{
			        this.Sorting = undefined;
			    }
			    this.getData();
			},
			recursion(rel) {
				console.log(rel)
			    if (rel.length > 0) {
			        for (let i = 0; i < rel.length; i++) {
			            // console.log(rel[i].id)
			            this.expandedTreeKeys.push(rel[i].id)
			            this.recursion(rel[i].children);
			        }
			    } else {
			        return
			    }
			},
			onTreeExpand(expandedKeys, record) {
			    this.expandedTreeKeys = expandedKeys;
			},
			//审核项分组选择
			onTreeSelect(selectedKeys, info) {
			    if (selectedKeys.length > 0) {
			        this.workProcessClass = undefined;
			        this.selectedTreeKeys = [];
			        this.treeNodeCode = [];
			        if(isNaN(selectedKeys[0])){
			            console.log(selectedKeys, info);
			            this.selectedTreeKeys = selectedKeys;
			            this.workProcessClass = info.selectedNodes[0].data.props.code;
			            console.log(this.workProcessClass);
			            //获取物料信息
			            this.getData();
			            // this.selectedRowKeys = [];
			            // this.selectedRows = [];
			        }
			        else{
			            console.log(selectedKeys[0], info);
						this.selected = selectedKeys[0];
			            this.selectedTreeKeys = selectedKeys;
			            this.treeNodeCode = selectedKeys;
			            if (this.selectedTreeKeys[0] == 'null' || this.treeNodeCode == 'W01' || this.treeNodeCode == 'W02' || this.treeNodeCode == 'W03' || this.treeNodeCode == 'W04') {
			                this.groupAct = true;
			            } else {
			                this.groupAct = false;
			            }
			            //获取物料信息
			            this.getData();
			            // this.selectedRowKeys = [];
			            // this.selectedRows = [];
			        }
			    
			    }
			},
			//审核项分组新增
			createOrEdit(cases) {
			    ModalHelper.create(CreatErrorCode, {
			        isEdit: false,
			        id: this.selected
			    },{width:400}).subscribe(res => {
			        console.log(res)
					if(res){
						this.getTree();
					}
			    })
			},
			//审核项分组编辑
			createOrEditMachineSupplier(cases) {
				console.log(this.checkedTreeKeys);
			    ModalHelper.create(CreatErrorCode, {
			        isEdit: true,
			        id: this.selected
			    },{width:400}).subscribe(res => {
					if(res){
						this.getTree();
					}
			    })
			},
			//审核项分组删除
			batchdelete(){
				if (this.selected <= 0 || this.selected == null) {
				    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
				    return;
				}
				// console.log(this.children);
				// if (this.children[0] != undefined) {
				//     abp.message.warn(this.l("问题分类下存在问题分类，不可删除"));
				//     return;
				// }
				this.$confirm(
				    {
				        title: this.l("ConfirmInformation"),
				        content: this.l("the_selected_option"),
				        cancelText: this.l('Cancel'),
				        okText: this.l('Ok'),
				        onOk: () => {
				            this.AuditItemGroupServiceProxy.delete(this.selected).then(res => {
				                this.getTree();
				                this.$notification["success"]({
				                    message: this.l("SuccessfullyDeleted"),
				                });
				            })
				        }
				    })
			},
			//获取数据
			getData() {
				this.selectedRowKey = [];
				this.selectedRowList = [];
				this.isLoading = true;
				this.AudititemServiceProxy.getPaged(
				this.workProcessClass,
						this.selected,
				this.filterText ? this.filterText : undefined,
				this.Sorting,
				this.request.maxResultCount,
				this.request.skipCount
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					console.log(res);
					this.tableData = res.items;
					
					this.totalItems = res.totalCount;
					this.totalPages = Math.ceil(
					    res.totalCount / this.request.maxResultCount
					);
					this.pagerange = [
					    (this.pageNumber - 1) * this.request.maxResultCount + 1,
					    this.pageNumber * this.request.maxResultCount,
					];
				})
			},
			//获取审核项类型
			getWorkProcessData() {
				this.isLoading = true;
				this.AuditItemtypeServiceProxy.getAllDate(
				this.filterText ? this.filterText : undefined,
				undefined,
				this.request.maxResultCount,
				this.request.skipCount
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					console.log(res);
				    this.workProcessData = JSON.parse(JSON.stringify(res));
				})
			},
			view(record) {
				console.log(record);
				  ModalHelper.create(view, {
					  teby: record
				  },{width:1000}).subscribe(res => {
				      console.log(res)
				      this.getData();
					this.selectedRowKey = [];
				  })
			},
			handleEdit(record) {
			    // console.log(record);
				var entity = {
					"id":record.id,
				};
				if(record.enable==true){
					this.enable = false;
				}else {
					this.enable = true;
				}
				this.isLoading = true;
				this.AudititemServiceProxy.isDetelt(
				this.enable,
				entity,
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.getData();
					this.selectedRowKey = [];
				})
			},
			//新增
			create(cases) {
			    ModalHelper.create(Creat, {
			        isEdit: false,
			        id: undefined
			    },{width:1100}).subscribe(res => {
			        console.log(res)
					if (res) {
					    this.getData();
					}
					this.selectedRowKey = [];
			    })
			},
			//编辑
			createOrEditM(record) {
				// console.log(record);
			    ModalHelper.create(Creat, {
			        isEdit: true,
			        id: record.id
			    },{width:1100}).subscribe(res => {
			        console.log(res)
			        if (res) {
			            this.getData();
			        }
					// this.selectedRowKey = [];
			    })
			},
			//批量删除事件
			delete1(){
				if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
				    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
				    return;
				}
				// if (this.children[0] != undefined) {
				//     abp.message.warn(this.l("审核点下存在审核点，不可删除"));
				//     return;
				// }
				this.$confirm(
				    {
				        title: this.l("ConfirmInformation"),
				        content: this.l("the_selected_option"),
				        cancelText: this.l('Cancel'),
				        okText: this.l('Ok'),
				        onOk: () => {
				            this.AudititemServiceProxy.batchDelete(this.selectedRowKey).then(res => {
				                this.getData();
				                this.$notification["success"]({
				                    message: this.l("SuccessfullyDeleted"),
				                });
				            })
				        }
				    })
			},
			//审核项删除
			delete2(record) {
				this.$confirm(
				    {
				        title: this.l("ConfirmInformation"),
				        content: this.l("the_selected_option"),
				        cancelText: this.l('Cancel'),
				        okText: this.l('Ok'),
				        onOk: () => {
				            this.AudititemServiceProxy.delete(record.id).then(res => {
				                this.getData();
				                this.$notification["success"]({
				                    message: this.l("SuccessfullyDeleted"),
				                });
				            })
				        }
				    })
			},
			//行选择
			customRow: (record) => {
			    return {
			        on: {
			            click: () => {
			                _this.onSelect(record)
			            }
			        }
			    }
				
			},
			//多选
			SelectAll(record, rows, crows) {
			  if (record) {
			      //更改表格数据勾选状态
			      this.tableData.map(item => {
			          item.checked = true;
			      });
			      rows.forEach((item, index) => {
			          //判断是否存在
			          let u = this.selectedRowKey.find(t => t == item.id);
			          if (!u) {
			              this.selectedRowKey.push(item.id);
			              this.selectedRowList.push(item);
			          }
			      });
			  } else {
			      //更改表格数据勾选状态
			      this.tableData.forEach((item, index) => {
			          item.checked = false;
			          this.selectedRowKey = [];
			          this.selectedRowList = [];
			      });
			  }
			},
			// 重写复选框点击事件(不可删除)
			onSelect(record) {
			    //debugger;
			    record.checked = !record.checked;
			    if (record.checked) {
			        this.selectedRowKey.push(record.id);
					this.children.push(record.children);
					this.selectedRowList.push(record);
					this.name = record.name;
					this.number = record.code;
			    } else {
			        this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
					this.children = _.difference(_this.children, [record.children]);
					this.selectedRowList = _.difference(_this.selectedRowList, [record]);
			    }
			},
			//搜索框点击事件
			filteronchange() {
			    this.pageNumber = 1;
			    this.request.skipCount = 0;
			    this.getData();
			},
			workProcessClassChange(key){
				console.log(key);
				this.workProcessClass = key;
				this.getData();
			},
			filterOption(input, option){
			    return (
			        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			    );
			},
			//分页
			onChangePage(page, pageSize) {
			    this.selectedRowKey = [];
			    this.pageNumber = page;
			    this.request.skipCount = (page - 1) * this.request.maxResultCount;
			    this.getData();
			},
			//正常分页展示形式，显示（共有 {0} 条）
			// showTotalFun() {
			// 	console.log(this.totalItems);
			//     return this.l(
			//         "GridFooterDisplayText",
			//         this.totalItems
			//     );
			// },
			showSizeChange(current, size) {
			    this.selectedRowKey = [];
			    this.pageNumber = 1;
			    this.request.maxResultCount = size;
			    this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
			    this.getData();
			},
			//导入
			Export() {
			    ModalHelper.create(
			        ImportExcelModel,
			        {
			        },
			        {
			            width: "800px"
			        }
			    ).subscribe(res => {
			        this.getData();
			    });
			},
			//导出
			handleMenuClick() {
				this.AudititemServiceProxy.getToExcelFile(
                    this.workProcessClass,
						this.selected,
				this.filterText ? this.filterText : undefined,
				this.Sorting,
				this.request.maxResultCount,
				this.request.skipCount,
				    this.selectedRowKey
				).then(res => {
				    this._fileDownloadService.downloadTempFile(res);
				})
			},
		}
	}
</script>

<style>
	.pagination {
	    margin: 10px auto;
	    text-align: right;
	}

	.con {
	    padding-bottom: 10px;
	}
</style>
