<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>
                    <a-col :span="24" class="body">
                        <a-col :span="8">
                            <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }"
                                :label="l('audititem.audititemgroupid')">
                                <a-tree-select v-model="auditItemGroupId" style="width: 100%" :tree-data="treeDataList"
                                    :replace-fields="treeReplaceFields"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :placeholder="l('please_select_a_review_item_group')" allow-clear
                                    tree-default-expand-all>
                                </a-tree-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }"
                                :label="l('audititem.audititemtypeid')">
                                <a-tree-select v-model="auditItemTypeIdList" style="width: 100%" :tree-data="treeData"
                                    :replace-fields="treeReplace" multiple
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
                                    :placeholder="l('please_select_the_review_item_type')"
                                    :dropdownMatchSelectWidth="false" allow-clear tree-default-expand-all>
                                </a-tree-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 12 }"
                                :label="l('Code')">
                                <a-input v-model="code" :placeholder="l('CourseCategoryCodeInputDesc')"
                                    style="width: 100%;"></a-input>
                            </a-form-item>
                        </a-col>
                        
                    </a-col>
                    
                    <a-col :span="24" class="body" style="text-align: right;">

                        <a-col :span="15">
                            <a-form-item required :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }"
                                :label="l('audititem.name')" style="left:17px">
                                <a-textarea v-model="entity.name"></a-textarea>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }" :label="l('sn')">
                                <a-input-number v-model="sn" :placeholder="l('sort_from_small_to_large')"
                                    style="width: 100%;"></a-input-number>
                            </a-form-item>
                        </a-col>
                        <!-- <a-form-item required :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" :label="l('audititem.name')" style="right: 10px;">
							<a-textarea v-model="entity.name"></a-textarea>
						</a-form-item> -->
                    </a-col>
                    <a-col :span="24">
                        <a-col :span="8">
                            <a-col :span="7">
                                <a-form-item :label="l('audititem.choose')" style="bottom: 9px;">
                                </a-form-item>
                            </a-col>
                            <a-col :span="14">
                                <a-col :span="9">
                                    <a-switch style="bottom: 2px;left: 20px" :checked-children="l('Yes')"
                                        :un-checked-children="l('No')" v-model="entity.auditNeedPic" />
                                </a-col>
                                <span>{{l('audititem.auditneedpic')}}</span>
                            </a-col>
                        </a-col>
                        <a-col :span="8">
                            <a-col :span="9" style="text-align: right;">
                                <a-switch style="bottom: 2px;" :checked-children="l('Yes')"
                                    :un-checked-children="l('No')" v-model="entity.auditNeedDesc" />
                            </a-col>
                            <a-col :span="12" style="left: 20px;">
                                <span>{{l('audititem.auditneeddesc')}}</span>
                            </a-col>
                        </a-col>
                        <a-col :span="8">
                            <a-col :span="9" style="text-align: right;">
                                <a-switch style="bottom: 2px;" :checked-children="l('Yes')"
                                    :un-checked-children="l('No')" v-model="entity.isAllowTransfer" />
                            </a-col>
                            <a-col :span="12" style="left: 20px;">
                                <span>{{l('AllowTransfer')}}</span>
                            </a-col>
                        </a-col>
                    </a-col>
                    <a-col :span="24" class="body">
                        <a-col :span="8">
                            <a-col :span="7">
                                <a-form-item :label="l('audititem.problemchoose')" style="bottom: 9px;">
                                </a-form-item>
                            </a-col>
                            <a-col :span="14">
								<a-col :span="9">
								    <a-switch style="bottom: 2px;left: 20px" :checked-children="l('Yes')"
								        :un-checked-children="l('No')" v-model="entity.unQualifiedMustSubmitIssue" />
								</a-col>
								<span style="left: 20px;">{{l('Select_Non-compliant_to_submit_questions')}}</span>
                            </a-col>
                        </a-col>
						<a-col :span="8">
							<a-col :span="10" style="text-align: right;">
							    <a-switch style="bottom: 2px;margin-right: 15px;" :checked-children="l('Yes')"
							        :un-checked-children="l('No')" v-model="entity.issueNeedPic" />
							</a-col>
							<span style="margin-left: 4px;">{{l('audititem.auditneedpic')}}</span>
						</a-col>
						<a-col :span="8" class="body">
							<a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }"
							    :label="l('audititem.issuetypeid')" style="bottom: 9px;">
							    <a-tree-select v-model="issueTypeIdList" style="width: 200px;" :tree-data="tree"
							    multiple
							        :replace-fields="treeRep" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							        :placeholder="l('please_choose_the_type')" allow-clear tree-default-expand-all>
							    </a-tree-select>
							</a-form-item>
						</a-col>
                    </a-col>
                    <a-col :span="24" class="body">
                        <a-col :span="8">
                            <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }"
                                :label="l('Option_Type')" style="bottom: 9px;">
                                <a-select v-model="entity.optionType" showSearch :filterOption="filterOption"
                                    style="width: 200px;text-align: left;margin-right: 10px;"
                                    dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                    :placeholder="l('please_select_Option_Type')" @change="OptionTypeChange" allow-clear>
                                    <a-select-option v-for="item in OptionTypeList" :key="item.itemDetailCode"
                                        :title="item.itemDetailName">
                                        {{ item.itemDetailName }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="8" v-if="entity.optionType != '3'">
                            <a-form-item
                                v-if="entity.optionType == '1' || entity.optionType == '2' || entity.optionType == '4'"
                                required :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" :label="l('StandardValue')"
                                style="bottom: 9px;">
                                <a-select v-if="entity.optionType == '1'" v-model="entity.valueStandard" showSearch :filterOption="filterOption"
                                    style="width: 200px;text-align: left;margin-right: 10px;"
                                    dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                    :placeholder="l('Please_select_a_value')" @change="BoolChange" allow-clear>
                                    <a-select-option key="true" title="true">
                                        OK
                                    </a-select-option>
                                    <a-select-option key="false" title="false">
                                        NG
                                    </a-select-option>
                                </a-select>
                                <a-input v-if="entity.optionType != '1'" v-model="entity.valueStandard"
                                    :placeholder="l('Please_enter_data')" style="width: 100%;">
                                </a-input>
                            </a-form-item>
                            <a-form-item v-if="entity.optionType == '5'" required :label-col="{ span: 7 }"
                                :wrapper-col="{ span: 14 }" :label="l('≤')" style="bottom: 9px;">
                                <a-input v-model="entity.valueStandard" :placeholder="l('Please_enter_data')" style="width: 100%;">
                                </a-input>
                            </a-form-item>
                            <a-form-item v-if="entity.optionType == '6'" required :label-col="{ span: 7 }"
                                :wrapper-col="{ span: 14 }" :label="l('≥')" style="bottom: 9px;">
                                <a-input v-model="entity.valueStandard" :placeholder="l('Please_enter_data')" style="width: 100%;">
                                </a-input>
                            </a-form-item>
                            <a-form-item v-if="entity.optionType == '7'" required :label-col="{ span: 7 }"
                                :wrapper-col="{ span: 14 }" :label="l('Option_List')" style="bottom: 9px;">
                                <a-select showSearch :filterOption="filterOption" v-model="entity.valueStandard"
                                    style="width: 200px;text-align: left;margin-right: 10px;"
                                    dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                    :placeholder="l('Please_select_a_list')" @change="OptionListChange" allow-clear>
                                    <a-select-option v-for="item in OptionList" :key="item.id + ''" :title="item.name">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8" v-if="entity.optionType == '3' || entity.optionType == '4'">
                            <a-form-item v-if="entity.optionType == '3'" required :label-col="{ span: 7 }"
                                :wrapper-col="{ span: 14 }" :label="l('LowerLimit')" style="bottom: 9px;">
                                <a-input-number v-model="entity.valueLowerLimit" :placeholder="l('Please_enter_a_lower_limit')"
                                    style="width: 100%;">
                                </a-input-number>
                            </a-form-item>
                            <a-form-item v-if="entity.optionType == '4'" required :label-col="{ span: 10 }"
                                :wrapper-col="{ span: 9 }" :label="l('NegativeDifference')" style="bottom: 9px;">
                                <a-input-number v-model="entity.valueLowerLimit" :placeholder="l('Please_enter_a_negative_difference')"
                                    style="width: 100%;">
                                </a-input-number>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8" v-if="entity.optionType == '3' || entity.optionType == '4'">
                            <a-form-item v-if="entity.optionType == '3'" required :label-col="{ span: 7 }"
                                :wrapper-col="{ span: 14 }" :label="l('MaximumLimit')" style="bottom: 9px;">
                                <a-input-number v-model="entity.valueUpperLimit" :placeholder="l('Please_enter_a_maximum_limit')" :min="entity.valueLowerLimit"
                                    @blur="prompt" style="width: 100%;">
                                </a-input-number>
                            </a-form-item>
                            <a-form-item v-if="entity.optionType == '4'" required :label-col="{ span: 10 }"
                                :wrapper-col="{ span: 9 }" :label="l('PositiveDifference')" style="bottom: 9px;">
                                <a-input-number v-model="entity.valueUpperLimit" :placeholder="l('Please_ente_ the_positive_difference')"
                                    style="width: 100%;">
                                </a-input-number>
                            </a-form-item>
                        </a-col>
                    </a-col>

                    <a-col :span="24" class="body">
                        <a-col :span="8">
                            <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }"
                                :label="l('Unit')" style="bottom: 9px;">
                                <a-select showSearch :filterOption="filterOption"
									v-model="entity.valueUnit"
                                    style="width: 200px;text-align: left;margin-right: 10px;"
                                    dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                    :placeholder="l('select_Unit')" @change="AuditItemUnitChange" allow-clear>
                                    <a-select-option v-for="item in AuditItemUnitList" :key="item.itemDetailCode"
                                        :title="item.itemDetailName">
                                        {{ item.itemDetailName }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
						<a-col :span="8" v-if="entity.optionType == '1' || entity.optionType == '7'">
						    <a-col :span="9" style="text-align: right;">
						        <a-switch style="bottom: 2px;" :checked-children="l('Yes')"
						            :un-checked-children="l('No')" v-model="entity.auditNeedInput" />
						    </a-col>
						    <a-col :span="12" style="left: 20px;">
						        <span>{{l('audititem.audineedinput')}}</span>
						    </a-col>
						</a-col>
                    </a-col>

                    <a-col :span="24" style="right: 20px;">
                        <a-col :span="3" class="key-name">
                            <a-form-item :label="l('audititem.description')">

                            </a-form-item>
                        </a-col>
                        <a-col :span="20">
                            <editor v-model="entity.description" disabled="false" @onChange="editorOnChang"></editor>
                        </a-col>
                    </a-col>
                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {
        AuditItemGroupServiceProxy,
        AuditItemtypeServiceProxy,
        IssueTypeServiceProxy,
        AudititemServiceProxy,
        AuditItemEditDto,
        OptionListServiceProxy
    }
        from '../../../../shared/service-proxies';
    import Editor from "../../../../components/editor/editor";
    import { Dic, fileDownloadService } from "../../../../shared/utils";
    // import Editor2 from "../../../../components/editer2/editer2";

    let _this;
    export default {
        name: 'new-audit',
        mixins: [ModalComponentBase],
        components: {
            Editor,
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this.AuditItemGroupServiceProxy = new AuditItemGroupServiceProxy(this.$apiUrl, this.$api);
            this.AuditItemtypeServiceProxy = new AuditItemtypeServiceProxy(this.$apiUrl, this.$api);
            this.IssueTypeServiceProxy = new IssueTypeServiceProxy(this.$apiUrl, this.$api);
            this.AudititemServiceProxy = new AudititemServiceProxy(this.$apiUrl, this.$api);
            this.OptionListServiceProxy = new OptionListServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
            this.OptionTypeList = await Dic.getInstance().getDicAsync('OptionType');
            this.AuditItemUnitList = await Dic.getInstance().getDicAsync('AuditItemUnit');
            this.getOptionList();
            this.getData();
            this.getTreeData();
            this.getTreeData1();
            this.getTreeData2()
        },
        data() {
            return {
                zh_CN,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
                entity: new AuditItemEditDto(),
                pId: undefined,
                auditItemGroupId: undefined,
                auditItemTypeIdList: [],
                sn: undefined,
                code:undefined,
                issueTypeIdList: [],
                treeDataList: [],
                treeData: [],
                tree: [],
                treeRep: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                treeReplace: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                treeReplaceFields: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                isclick: true,

                OptionTypeList: [],
                OptionList: [],
                AuditItemUnitList:[],
            }
        },
        methods: {

            AuditItemUnitChange(value) {
                this.entity.valueUnit = value;
            },
            BoolChange(value) {
                this.entity.valueStandard = value;
            },

            OptionListChange(value) {
                this.entity.valueStandard = value;
            },

            OptionTypeChange(value) {
                this.entity.valueStandard = undefined;
                this.entity.valueUpperLimit = null;
                this.entity.valueLowerLimit = null;
                this.entity.optionType = value;
				if(value != '1' && value != '7') {
					this.entity.auditNeedInput = false;
				}
            },

            getData() {
                this.isLoading = true;
                this.AudititemServiceProxy.getForEdit(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.entity = res;
                    // console.log(this.entity.code);
					if(!this.isEdit) {
						this.entity.unQualifiedMustSubmitIssue = true;
					}
                    if (this.entity.auditItemGroupId == 0) {
                        this.auditItemGroupId = undefined;
                    } else {
                        this.auditItemGroupId = res.auditItemGroupId;
                    }
                    if (this.entity.auditItemTypeIdList == []) {
                        this.auditItemTypeIdList = [];
                    } else {
                        this.auditItemTypeIdList = res.auditItemTypeIdList;
                    }
                    if (this.entity.sn == 0) {
                        this.sn = undefined;
                    } else {
                        this.sn = res.sn;
                    }
                    if (this.entity.issueTypeIdList == "" || this.entity.issueTypeIdList == null) {
                        this.issueTypeIdList = [];
                    } else {
                        this.issueTypeIdList = res.issueTypeIdList.split(',');
                    }
                    this.code = res.code;
                    // console.log(this.pId);
                })
            },

            getOptionList() {
                this.isLoading = true;
                this.OptionListServiceProxy.getAllOptionList(
                    undefined
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        this.OptionList = JSON.parse(JSON.stringify(res));
                    })
            },

            getTreeData() {
                this.isLoading = true;
                this.AuditItemGroupServiceProxy.getPaged(
                    undefined
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        this.treeDataList = JSON.parse(JSON.stringify(res));
                    })
            },
            getTreeData1() {
                this.isLoading = true;
                this.AuditItemtypeServiceProxy.getAllDate(
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.treeData = JSON.parse(JSON.stringify(res));
                    console.log(this.treeData);
                })
            },
            getTreeData2() {
                this.isLoading = true;
                this.IssueTypeServiceProxy.getPaged(
                    undefined
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.tree = JSON.parse(JSON.stringify(res));
                })
            },
            editorOnChang(e) {
                this.entity.description = e;
            },
            save() {
                // if (!this.entity.name) {
                //     abp.message.info('分类名称不能为空');
                //     return;
                // }
				if(Number(this.entity.valueUpperLimit) < Number(this.entity.valueLowerLimit) ) {
					abp.message.warn(this.l("The_upper_limit_of_the_interval_value_cannot_be_lower_than_the_lower_bound"));
				}
                if (this.auditItemGroupId== 0 || this.auditItemGroupId == undefined) {
                    abp.message.warn(this.l("please_select_a_review_item_group"));
                    return;
                }
                if (this.auditItemTypeIdList.length <= 0) {
                    abp.message.warn(this.l("please_select_the_review_item_type"));
                    return;
                }
                if (this.entity.name == "" || this.entity.name == null) {
                    abp.message.warn(this.l("please_enter_the_audit_item"));
                    return;
                }
                if (this.entity.optionType == "" || this.entity.optionType == null) {
                    abp.message.warn(this.l("please_select_the_option_list"));
                    return;
                }
                if (this.entity.valueStandard == "" || this.entity.valueStandard == null || this.entity.valueStandard == undefined) {
                    if (this.entity.optionType == '1' || this.entity.optionType == '4'){
                        abp.message.warn(this.l("please_enter_the_valueStandard"));
                        return;
                    }
                }
                if (this.auditItemGroupId == undefined) {
                    this.entity.auditItemGroupId = 0;
                } else {
                    this.entity.auditItemGroupId = this.auditItemGroupId;
                }
                if (this.auditItemTypeIdList == []) {
                    this.entity.auditItemTypeIdList = [];
                } else {
                    this.entity.auditItemTypeIdList = this.auditItemTypeIdList;
                }
                if (this.sn == undefined) {
                    this.entity.sn = 0;
                } else {
                    this.entity.sn = this.sn;
                }
                this.entity.issueTypeIdList = this.issueTypeIdList.join();
                this.entity.code = this.code;
                console.log(this.issueTypeId);
                console.log(this.entity);
                // return;
                // this.table.auditItemGroupId = this.entity.auditItemGroupId;
                // console.log(this.table.auditItemGroupId);
                var auditItemEditDto = { auditItemEditDto: this.entity };
                console.log(auditItemEditDto);
                if (this.isclick) {
                    this.isclick = false;
                    this.AudititemServiceProxy.createOrUpdate(
                        auditItemEditDto
                    ).finally(() => {
                        this.close()
                    }).then(res => {
                        this.isclick = true;
                        this.success(true)
                    })
                }
            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
			
			prompt() {
				if(Number(this.entity.valueUpperLimit) <= Number(this.entity.valueLowerLimit) ) {
					abp.message.warn(this.l("The_upper_limit_of_the_interval_value_cannot_be_lower_than_the_lower_bound"));
				}
			}

        }
    }
</script>

<style>
    .body {
        margin-top: -10px;
    }

    .ant-form-item {
        margin-bottom: 10px;
    }
</style>