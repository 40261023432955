<template>
	<div>
		<div class="modal-header">
		    <div class="modal-title">
		        <a-icon type="medicine-box" style="margin-right:10px" />
		        <span>{{l('view_review_items')}}</span>
		    </div>
		</div>
		<div>
		    <a-config-provider :locale="zh_CN">
				<a-row>
				    <a-col :span="24">
						<a-col :span="8" class="boy">
							<a-col :span="12" class="key-name">
							    <p>{{l('audititem.audititemgroupid')}}:</p>
							</a-col>
							<a-col :span="12">
							    <p>{{ entity.auditItemGroupName }}</p>
							</a-col>
						</a-col>
						<a-col :span="8" class="boy">
							<a-col :span="12" class="key-name">
							    <p>{{l('audititem.audititemtypeid')}}:</p>
							</a-col>
							<a-col :span="12">
							    <p>{{ entity.auditItemTypeName }}</p>
							</a-col>
						</a-col>
						<a-col :span="8" class="boy">
							<a-col :span="12" class="key-name">
							    <p>{{l('sn')}}:</p>
							</a-col>
							<a-col :span="12">
							    <p>{{ entity.sn }}</p>
							</a-col>
						</a-col>
				    </a-col>
					<a-col :span="24" style="right: 40px;">
						<a-col :span="5" class="key-name">
						    <p>{{l('audititem.name')}}:</p>
						</a-col>
						<a-col :span="19">
						    <p>{{ entity.name }}</p>
						</a-col>
					</a-col>
					<a-col :span="24">
						<a-col :span="8" class="boy">
							<a-col :span="12" class="key-name">
							    <p>{{l('audititem.issuetypeid')}}:</p>
							</a-col>
							<a-col :span="12">
							    <p>{{ entity.issueTypeIdListName }}</p>
							</a-col>
						</a-col>
						<a-col :span="8" class="boy">
							<a-col :span="12" class="key-name">
							    <p>{{l('audititem.audineedinput')}}:</p>
							</a-col>
							<a-col :span="12">
							    <p>{{ auditNeedInput }}</p>
							</a-col>
						</a-col>
						<a-col :span="8" class="boy">
							<a-col :span="12" class="key-name">
							    <p>{{l('audititem.auditneedpic')}}:</p>
							</a-col>
							<a-col :span="12">
							    <p>{{ auditNeedPic }}</p>
							</a-col>
						</a-col>
					</a-col>
					<a-col :span="24">
						<a-col :span="8" class="boy">
							<a-col :span="12" class="key-name">
							    <p>{{l('audititem.issueneedpic')}}:</p>
							</a-col>
							<a-col :span="12">
							    <p>{{ issueNeedPic }}</p>
							</a-col>
						</a-col>
						<a-col :span="8" class="boy">
							<a-col :span="12" class="key-name">
							    <p>{{l('audititem.auditneeddesc')}}:</p>
							</a-col>
							<a-col :span="12">
							    <p>{{ auditNeedDesc }}</p>
							</a-col>
						</a-col>
					</a-col>
					<a-col :span="24" style="right: 40px;">
						<a-col :span="5" class="key-name">
						    <p>{{l('audititem.description')}}:</p>
						</a-col>
						<a-col :span="19">
						    <editor :value="entity.description"></editor>
						</a-col>
					</a-col>
				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
		    <a-button @click="close">
		        {{l('Cancel')}}
		    </a-button>
		</div>
	</div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { AuditItemGroupServiceProxy } from '../../../../shared/service-proxies';
	import Editor from "../../../../components/editor/editor";
	
	let _this;
	export default {
		name: 'view',
		mixins: [ModalComponentBase],
		components: {
		    Editor,
		},
		created() {
		    this.fullData(); // 模态框必须,填充数据到data字段
		    this.AuditItemGroupServiceProxy = new AuditItemGroupServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
		    this.getData();
		},
		data() {
		    return {
				zh_CN,
		        isEdit: false,//是否是编辑
		        isLook: false,//是否是查看
		        entity: [],
				pId: undefined,
				treeDataList: [],
				treeReplaceFields: {
				    title: 'name',
				    key: 'id',
				    value: 'id'
				},
				auditNeedPic: undefined,
				auditNeedInput: undefined,
				auditNeedDesc: undefined,
				issueNeedPic: undefined,
		    }
		},
		methods: {
			getData() {
				this.entity = this.teby;
				console.log(this.entity);
				if(this.entity.auditNeedPic==false){
					this.auditNeedPic = this.l('No');
				}else{
					this.auditNeedPic = this.l('Yes');
				}
				if(this.entity.auditNeedInput==false){
					this.auditNeedInput = this.l('No');
				}else{
					this.auditNeedInput = this.l('Yes');
				}
				if(this.entity.auditNeedDesc==false){
					this.auditNeedDesc = this.l('No');
				}else{
					this.auditNeedDesc = this.l('Yes');
				}
				if(this.entity.issueNeedPic==false){
					this.issueNeedPic = this.l('No');
				}else{
					this.issueNeedPic = this.l('Yes');
				}
			},
			
		}
	}
</script>

<style>
	.key-name {
	    text-align: right;
	    padding-right: 10px;
	}
</style>
